import { FC } from 'react';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { StyledButton } from './style';
import { SlickArrowProps } from './type';

const SlickArrowLeft: FC<SlickArrowProps> = ({ currentSlide, ...props }) => (
  <StyledButton
    {...props}
    className={`slick-prev slick-arrow${currentSlide === 0 ? " slick-disabled" : ""}`}
    aria-hidden="true"
    aria-disabled={currentSlide === 0}
    type="button"
  >
    <ArrowLeftOutlined />
  </StyledButton>
);

const SlickArrowRight: FC<SlickArrowProps> = ({ currentSlide, slideCount, ...props }) => (
  <StyledButton
    {...props}
    className={`slick-next slick-arrow${currentSlide === slideCount - 1 ? " slick-disabled" : ""}`}
    aria-hidden="true"
    aria-disabled={currentSlide === slideCount - 1}
    type="button"
  >
    <ArrowRightOutlined />
  </StyledButton>
);

export {
  SlickArrowLeft,
  SlickArrowRight
};

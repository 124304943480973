import { Button } from 'antd';
import styled from 'styled-components';
import { theme } from '../../../styles/theme';

export const ButtonWrapper = styled(Button)`
  height: 2rem;
  width: 100%;
  margin: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.color.secondary};
  color: ${theme.color.primary};
  text-transform: uppercase;
  box-shadow: 0 0 0 rgba(0, 8, 16, 0.51);
  transition: box-shadow 0.3s ease; /* Adding a smooth transition effect for hover */

  &:hover {
    background-color: ${theme.color.primary} !important;
    color: white !important;
    cursor: pointer;
  }
`;

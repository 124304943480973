import { Col, DatePicker, Form, Input, Row } from "antd";
import PrimaryButton from "../Buttons/PrimaryButton";
import TextArea from "antd/es/input/TextArea";
import { v4 as uuidv4 } from "uuid";
import { TAIL_FORM_ITEM_LAYOUT } from "../../constants";
import { useCollection } from "../../context/collections";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase/config";
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const RequestForm = ({ onFinish, loading }: any) => {
  const { state: collections, } = useCollection();
  const { t } = useTranslation();
  const domain = window.location.origin;
  const productLinks = collections.items.map(
    (item) => `${domain}/products/${item.id}`
  );

  const [form] = Form.useForm();
  const fullName = Form.useWatch("fullName", form);
  const email = Form.useWatch("email", form);
  const phoneNumber = Form.useWatch("phoneNumber", form);
  const dateTime = Form.useWatch("dateTime", form);
  const description = Form.useWatch("description", form);

  const handleSubmit = async (values: any) => {
    const id = uuidv4();
    const { fullName, email, phoneNumber, description } = values;
    await setDoc(doc(db, "requests", id), {
      id,
      fullName,
      email,
      phoneNumber,
      description,
      status: 'pending',
      dateTime: values?.dateTime ? new Date(values?.dateTime) : "",
    });
    onFinish(values, form); // Pass form values to parent component
    return values;
    form.resetFields(); // Reset form fields after submission
  };

  return (
    <Form
      form={form}
      id="request-form"
      layout="vertical"
      onFinish={handleSubmit}
      initialValues={{
        links: productLinks.join("\n"),
        collection: collections.collectionScreenshot,
        description: '',
        dateTime: ''
      }}
    >
      <Form.Item
        name="fullName"
        label={fullName ? t('form.label.full_name') : ""}
        rules={[{ required: true, message: t('form.error.full_name') }]}
      >
        <Input placeholder={t('form.placeholder.full_name')} name="fullName" />
      </Form.Item>
      <Form.Item
        name="email"
        label={email ? t('form.label.email') : ""}
        rules={[
          { required: true, message: t('form.error.email') },
          { type: "email", message: t('form.error.valid_email') },
        ]}
      >
        <Input placeholder={t('form.placeholder.email')} name="email" />
      </Form.Item>
      <Row gutter={[10, 10]} style={{ alignItems: 'flex-end' }}>
        <Col span={12}>
          <Form.Item
            name="phoneNumber"
            label={phoneNumber ? t('form.label.phone_number') : ""}
            rules={[
              { required: true, message: t('form.error.phone_number') },
              {
                pattern: /^[0-9]+$/,
                message: t('form.error.valid_phone_number'),
              },
            ]}
          >
            <Input name="phoneNumber" placeholder={t('form.placeholder.phone_number')} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="dateTime"
            label={dateTime ? t('form.label.preferred_date_of_call') : ""}
          >
            <DatePicker
              disabledDate={d => !d || d.isBefore(moment().format('YYYY-MM-DD'))}
              name="dateTime"
              showTime
              format="YYYY-MM-DD"
              style={{ width: "100%" }}
              placeholder={t('form.placeholder.preferred_date_of_call')}

            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="description"
        label={description ? t('form.label.brief_description') : ""}
      >
        <TextArea
          name="description"
          rows={4}
          placeholder={t('form.placeholder.brief_description')}
        />
      </Form.Item>
      <Form.Item name="links" hidden>
        <TextArea name="links" rows={4} />
      </Form.Item>
      <Form.Item name="collection" hidden>
        <Input name="collection" />
      </Form.Item>
      {/* <Form.Item
        name="projectDetails"
        label="Collection/Project name and other details"
        // initialValue="Project XYZ" // Example of readonly field value
      >
        <Input readOnly />
      </Form.Item> */}
      <Form.Item {...TAIL_FORM_ITEM_LAYOUT}>
        <PrimaryButton
          type="primary"
          htmlType="submit"
          style={{ marginTop: 20 }}
          disabled={loading}
          loading={loading}
        >
          {t('button.submit')}
        </PrimaryButton>
      </Form.Item>
    </Form>
  );
};

export default RequestForm;

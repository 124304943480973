import { collection, doc, getDoc, getDocs, query } from "firebase/firestore";
import { useCallback, useState } from "react";
import { db } from "../../firebase/config";
import { ProductType } from '../../types/type';

type useGetAllProductsType = {
  data: ProductType[]
  ids: string[]
  fetchData: () => void,
  loading: boolean
}

export default function useGetAllProducts(): useGetAllProductsType {
  const [products, setProducts] = useState<ProductType[]>([]);
  const [ids, setIds] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const refetch = useCallback(() => {
    async function fetchData() {
      setLoading(true);
      const docRef = query(collection(db, "products"));

      await getDocs(docRef)
        .then((docSnap) => {
          const databaseInfo: any[] = [];
          const dataIds: string[] = [];

          docSnap.forEach(async (doc) => {
            databaseInfo.push({
              ...doc.data(),
              id: doc.id,
              type: 'product'
            });
            dataIds.push(doc.id);
          });

          setIds(dataIds);
          setProducts(databaseInfo);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    fetchData();
  }, []);

  return {
    data: products,
    ids,
    fetchData: refetch,
    loading,
  };
}

export function useGetProduct(): any {
  const [user, setUser] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const refetch = useCallback((id: string) => {
    async function fetchData() {
      setLoading(true);
      try {
        const d = doc(db, "products", id);
        const docSnap = await getDoc(d);
        if (docSnap.exists()) {
          console.log(docSnap.data());
          setUser({
            ...docSnap.data(),
            id: docSnap.id,
          });
          return {
            ...docSnap.data(),
            id: docSnap.id,
          };
        }
        return null;
      } catch (error) {
        console.error('useGetProductError: ', error);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, []);


  return {
    data: user,
    fetchData: refetch,
    loading,
  };
}

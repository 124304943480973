import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import Header from "../Header";
import CustomDrawer from "../../components/Drawer";
import Footer from '../Footer';
import { StyledContent } from './style';

const MainLayout: React.FC = () => {
  return (
    <Layout
      style={{ height: "100%", alignSelf: "center", minHeight: "100dvh" }}
    >
      <Layout>
        <Header />
        <StyledContent>
          <Outlet />
          <CustomDrawer />
        </StyledContent>
        <Footer />
      </Layout>
    </Layout>
  );
};

export default MainLayout;

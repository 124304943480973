import { SVGProps } from "react";

const HideImageInfo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={32}
    height={33}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.66 16.481v11.005M25.84 21.661H14.835"
      stroke="#1E2D46"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <rect
      x={10.95}
      y={11.95}
      width={19.421}
      height={19.421}
      rx={4}
      stroke="#1E2D46"
      strokeWidth={2}
      strokeLinejoin="round"
    />
    <path
      d="m21.855 8.823-1.06-3.96a4 4 0 0 0-4.9-2.828L4.864 4.99A4 4 0 0 0 2.035 9.89l3.242 12.1c.414 1.543 2 2.46 3.544 2.046v0"
      stroke="#1E2D46"
      strokeWidth={2}
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);

export default HideImageInfo;

import React from 'react';
import { PrimaryButtonProps } from './type';
import { ButtonWrapper } from './style';

const PrimaryButton: React.FC<PrimaryButtonProps> = ({ children, onClick, ...props }) => {
  return (
    <ButtonWrapper onClick={onClick} {...props}>
      {children}
    </ButtonWrapper>
  )
}

export default PrimaryButton;

// import ImageCarousel from "../ImageCarousel";
import { ImageLayoutProps } from "./type";
import ImageWithCircles from "../../Image/ImageCoordinates";
// import { useState } from 'react';

const ImageLayout: React.FC<ImageLayoutProps> = ({
  projectImg,
  products,
  circles
}) => {
  // const [parentDimension, setParentDimension] = useState<{ width: number, height: number }>({ width: 0, height: 0 });
  // const getParentImageDimension = (dimension: { width: number, height: number }) => {
  //   setParentDimension(dimension);
  // };
  return (
    <>
      <ImageWithCircles
        imageUrl={projectImg}
        circles={circles}
        products={products}
      // dimension={getParentImageDimension}
      />
      {/* <ImageCarousel
        dimension={parentDimension}
        productImages={(products ?? []).filter((p) =>
          (circles ?? []).map((c) => c.id).includes(p.id)
        )}
      /> */}
    </>
  );
};

export default ImageLayout;

export const theme = {
  bg: {
    primary: '#1E2D46',
    secondary: '#C3D8E9'
  },
  color: {
    primary: '#1E2D46',
    secondary: '#C3D8E9'
  }
}

import { SVGProps } from "react";
import styled from 'styled-components';

const StyledBackArrow = styled.svg`
  width: 2.75rem;
  height: 0.5rem;
  fill: none;

  path {
    fill: #1E2D46;
    transition: fill 0.3s ease; /* Adding a smooth transition effect for hover */
  }

  &:hover {
    path {
      fill: /* Your hover color */;
    }
    cursor: pointer;
  }
`;

const BackArrow = (props: SVGProps<SVGSVGElement>) => (
  <StyledBackArrow xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M43 4.5h.5v-1H43v1ZM.646 3.646a.5.5 0 0 0 0 .708l3.182 3.182a.5.5 0 1 0 .708-.708L1.707 4l2.829-2.828a.5.5 0 1 0-.708-.708L.646 3.646ZM43 3.5H1v1h42v-1Z"
    />
  </StyledBackArrow>
)

export default BackArrow;

import React, {
  createContext,
  useContext,
  useReducer,
  useMemo,
  useCallback,
  ReactNode,
  useEffect
} from 'react';
import { ProductType } from '../types/type';

const initialState: CollectionState = {
  items: [],
  showDrawer: false,
  collectionScreenshot: ''
};

interface CollectionState {
  items: ProductType[]
  showDrawer: boolean,
  collectionScreenshot: string
}

type CollectionAction =
  | { type: 'INITIALIZE'; payload: ProductType[] }
  | { type: 'ADD_PRODUCT'; payload: ProductType }
  | { type: 'REMOVE_PRODUCT'; payload: string }
  | { type: 'TOGGLE_DRAWER' }
  | { type: 'RESET_COLLECTION' }
  | { type: 'SET_COLLECTION_SCREENSHOT'; payload: string };

interface CollectionProviderProps {
  children: ReactNode
}

const CollectionContext = createContext<{
  state: CollectionState;
  dispatch: React.Dispatch<CollectionAction>;
}>({
  state: { ...initialState },
  dispatch: () => null,
});

const collectionReducer = (state: CollectionState, action: CollectionAction): CollectionState => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        ...state,
        items: action.payload,
      };
    case 'ADD_PRODUCT':
      localStorage.setItem('collections', JSON.stringify([...state.items, action.payload]));
      return {
        ...state,
        items: [...state.items, action.payload],
      };
    case 'REMOVE_PRODUCT':
      localStorage.setItem('collections', JSON.stringify(state.items.filter(item => item.id !== action.payload)));
      return {
        ...state,
        items: state.items.filter(item => item.id !== action.payload),
      };
    case 'TOGGLE_DRAWER':
      return {
        ...state,
        showDrawer: !state.showDrawer
      }
    case 'RESET_COLLECTION':
      localStorage.removeItem('collections');
      return {
        ...state,
        items: initialState.items
      }
    case 'SET_COLLECTION_SCREENSHOT':
      return {
        ...state,
        collectionScreenshot: action.payload
      }
    default:
      return state;
  }
}

export const useCollection = () => {
  return useContext(CollectionContext);
}

export const CollectionProvider: React.FC<CollectionProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(collectionReducer, { ...initialState });

  useEffect(() => {
    const storedItems = localStorage.getItem('collections');
    if (storedItems) {
      const parsedItems = JSON.parse(storedItems);
      dispatch({ type: 'INITIALIZE', payload: parsedItems });
    }
  }, []);

  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <CollectionContext.Provider value={contextValue}>
      {children}
    </CollectionContext.Provider>
  );
};

export const useCollectionActions = () => {
  const { dispatch } = useContext(CollectionContext);

  const addCollection = useCallback((item: ProductType) => {
    dispatch({ type: 'ADD_PRODUCT', payload: item });
  }, [dispatch]);

  const removeCollection = useCallback((id: string) => {
    dispatch({ type: 'REMOVE_PRODUCT', payload: id });
  }, [dispatch]);

  const toggleDrawer = useCallback(() => {
    dispatch({ type: 'TOGGLE_DRAWER' });
  }, [dispatch]);

  const resetCollection = useCallback(() => {
    dispatch({ type: 'RESET_COLLECTION' });
  }, [dispatch]);

  const setCollectionScreenshot = useCallback((screenshot: string) => {
    dispatch({ type: 'SET_COLLECTION_SCREENSHOT', payload: screenshot })
  }, [dispatch]);

  return {
    addCollection,
    removeCollection,
    toggleDrawer,
    resetCollection,
    setCollectionScreenshot
  };
}

import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Col } from "antd";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import ImageLayout from "../../components/Projects/ImageLayout";
import useGetAllProjects from "../../hooks/projects/useGetAllProjects";
import Spinner from "../../components/common/Spinner";
// import { projectData } from "../../constants";

import {
  // ProjectContentWrapper,
  ProjectDetailsContainer,
  ProjectListContainer,
  ProjectsHeaderContainer,
  ProjectsWrapper,
} from "./style";
import useGetAllProducts from "../../hooks/products/useGetAllProducts";

const Projects = () => {
  const navigate = useNavigate();
  const { data: projects, fetchData, loading } = useGetAllProjects();
  const {
    data: productsData,
    fetchData: fetchAllProducts,
    loading: isFetchingAllProducts,
  } = useGetAllProducts();

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    fetchAllProducts();
  }, [fetchAllProducts]);

  const handleVisitProject = (id: string | number) => {
    navigate(`/projects/${id}`, { state: { fromProjects: true } });
  };

  if (loading || isFetchingAllProducts) return <Spinner size="large" />;

  return (
    <div
      style={{ maxWidth: "100%", display: "flex", justifyContent: "center" }}
    >
      <ProjectsWrapper>
        <ProjectsHeaderContainer>
          <h1>PROJECTS</h1>
          <p>
            For various clients with various tastes we created and applied eye
            catching designs. Every design is a mix of client’s input together
            with our years of experience and current trends. Get inspired and
            don’t hesitate to contact us.
          </p>
        </ProjectsHeaderContainer>
        <ProjectListContainer gutter={[71, 71]}>
          {(projects ?? []).map(
            (
              project: {
                id: string;
                url: string;
                products: string[];
                title: string;
                coordinates?: any | any[];
                subtitle: string;
                description: string;
              },
              index: any
            ) => (
              <Col lg={12} md={24} key={`${project.id}_${index}`}>
                <ImageLayout
                  projectImg={project.url}
                  circles={project?.coordinates}
                  products={productsData ?? []}
                  productImages={project?.products ?? []}
                />
                <ProjectDetailsContainer>
                  <h2>{project.title}</h2>
                  <p>{project.subtitle}</p>
                  <p>{project.description}</p>
                  {/* <Row gutter={[40, 40]}>
                {project?.contents.map((content) => (
                  <Col lg={12}>
                    <ProjectContentWrapper>
                      <Image src={content.image} />
                      <div>
                        <label>{content.title}</label>
                        <p>{content.description}</p>
                      </div>
                    </ProjectContentWrapper>
                  </Col>
                ))}
              </Row> */}
                  <PrimaryButton
                    onClick={() => handleVisitProject(project.id)}
                    type="primary"
                  >
                    VIEW PROJECT
                  </PrimaryButton>
                </ProjectDetailsContainer>
              </Col>
            )
          )}
        </ProjectListContainer>
      </ProjectsWrapper>
    </div>
  );
};

export default Projects;

import React from 'react';
import { Link } from 'react-router-dom';
import ReactCountryFlag from 'react-country-flag';
import { ProductType } from '../../types/type';
import Icon from '../../components/Icon/Icon';
import { Container, LinkContainer, TranslationWrapper } from './style';
import { MenuProps } from 'antd';
interface CollectionState {
  items: ProductType[];
  showDrawer: boolean;
  collectionScreenshot: string;
}

type SidebarProps = {
  i18n: { language: string };
  items: MenuProps['items'];
  onChangeLanguage: (key: string) => void;
  selectedLanguage: string;
  t: (key: string) => string;
  state: CollectionState;
  pathname: string;
  toggleDrawer: () => void;
  toggleMenu: () => void;
  banqaLogo: string;
};

const Sidebar: React.FC<SidebarProps> = ({
  i18n,
  onChangeLanguage,
  selectedLanguage,
  t,
  state,
  pathname,
  toggleDrawer,
  toggleMenu
}) => {
  return (
    <Container>
      <LinkContainer>
        <Link to="/shop" onClick={toggleMenu}>{t('navbar.shop')}</Link>
        <a href="https://www.banqa.es/portfolio">{t('navbar.portfolio')}</a>
        <a href="https://www.banqa.es/brands">{t('navbar.brands')}</a>
        <a href="https://www.banqa.es/homestaging/">{t('navbar.homestaging')}</a>
        <a href="https://www.banqa.es/interior-designer/">{t('navbar.interiorDesigner')}</a>
        <a href="https://www.banqa.es/about/">{t('navbar.about')}</a>
        <a href="https://www.banqa.es/art/">{t('navbar.art')}</a>
        <a href="https://www.banqa.es/contact-us/">{t('navbar.contact')}</a>
        {pathname !== '/collections' && (
          <div style={{ display: 'flex', alignItems: 'center', padding: '.5rem' }}>
            <span>{state.items.length}</span>
            <button
              onClick={toggleDrawer}
              style={{ background: 'none', border: 'none', cursor: 'pointer', padding: 0 }}
            >
              <Icon name="collections" size={20} />
            </button>
          </div>
        )}
        <TranslationWrapper onClick={() => onChangeLanguage(i18n.language === 'gb' ? 'es' : 'gb')}>
          <ReactCountryFlag
            countryCode={selectedLanguage}
            svg
            style={{
              width: '3em',
              height: '3em',
              marginRight: '0.5em',
            }}
          />
          <span className="language-text">
            {i18n.language === 'gb' ? 'EN' : 'ES'}
          </span>
        </TranslationWrapper>
      </LinkContainer>
    </Container>
  );
};

export default Sidebar;

import React from 'react';
import { SecondaryButtonProps } from './type';
import { ButtonWrapper } from './style';

const SecondaryButton: React.FC<SecondaryButtonProps> = ({ children, onClick, ...props }) => {
  return (
    <ButtonWrapper onClick={onClick} {...props}>
      {children ?? ''}
    </ButtonWrapper>
  )
}

export default SecondaryButton;

import React from "react";
import { PrimaryButtonProps } from "./type";
import { SpinnerWrapper } from "./style";

const Spinner: React.FC<PrimaryButtonProps> = ({
  size = "small",
  ...props
}) => {
  return <SpinnerWrapper size={size} {...props} />;
};

export default Spinner;

import { useState } from 'react';
import { DEFAULT_IMAGE_PLACEHOLDER } from '../../constants';
import Spinner from './Spinner';

export default function ImageWithLoader({ src }: { src: string }) {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  const handleLoad = () => {
    setIsLoading(false);
  };

  const handleError = (e: any) => {
    setIsLoading(false);
    setError(true);
    e.target.src = DEFAULT_IMAGE_PLACEHOLDER;
  };

  return (
    <>
      {isLoading && <Spinner size="large" />}
      {error && <div>Error loading image.</div>}
      <img
        src={src || DEFAULT_IMAGE_PLACEHOLDER}
        // src={prod.img}
        onLoad={handleLoad}
        onError={handleError}
        style={{ width: '100%', height: '100%' }}
      />
    </>
  );
}
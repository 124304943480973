export const getRandomValue = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const checkLink = async (url: string) => {
  try {
    const response = await fetch(url, { method: 'HEAD' });

    if (!response.ok) {
      throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
    }

    return true;
  } catch (error) {
    console.error('Error checking link:', error);
    return false;
  }
};

export const getRandomSize = (minWidth: number, maxWidth: number, minHeight: number, maxHeight: number) => {
  const randomWidth = Math.floor(Math.random() * (maxWidth - minWidth + 1) + minWidth);
  const randomHeight = Math.floor(Math.random() * (maxHeight - minHeight + 1) + minHeight);
  return { width: randomWidth, height: randomHeight };
}
import { Button } from 'antd';
import styled from 'styled-components';
import { theme } from '../../../styles/theme';

export const ButtonWrapper = styled(Button)`
  height: 2.688rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 0 rgba(0, 8, 16, 0.51);
  transition: box-shadow 0.3s ease; /* Adding a smooth transition effect for hover */

  &:hover {
    color: ${theme.color.primary} !important;
    cursor: pointer;
  }
`;

import { Layout } from 'antd';
import styled from 'styled-components';

export const StyledContent = styled(Layout.Content)`
  width: auto;
  display: flex;
  justify-content: center;
  background: white;

  @media (max-width: 770px) {
    display: block;
  }
`;
import styled, { keyframes } from 'styled-components';
import { OpenPreviewContainerProps, ProductImageContainerProps, ProductImageOverlayProps } from '../Products/type';
import { theme } from '../../styles/theme';
import { CollectionDrawerProps } from './type';
import { Image, Row } from 'antd';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const slideTop = keyframes`
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const ProductsWrapper = styled.div`
  width: 100%;
  padding: 2rem;
`;

export const ProductsCategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3.688rem;
`;

export const ProductsTitle = styled.h1`
  font-family: Raleway;
  font-weight: 400;
  font-size: 2rem;
  color: ${theme.color.primary};
  text-transform: uppercase;
`;

export const ProductCategories = styled.div`
  display: flex;
  gap: 2.5rem;
`;

export const OpenPreviewContainer = styled.div<OpenPreviewContainerProps>`
  position: absolute;
  bottom: 1.25rem;
  right: 0.625rem;

  button {
    display: ${props => props.show ? 'none' : 'flex'};
    justify-content: center;
    align-items: center;
    padding: 1.125rem !important;
  }
`;

export const MasonryLayout = styled.div`
  margin: 1.25rem auto;
  columns: 5;
  column-gap: 0.625rem;
  rows: 2;
`;

export const ProductImageContainer = styled.div<ProductImageContainerProps>`
  cursor: pointer;
  position: relative;
  width: 100%;
  margin-bottom: 0.313rem;
  break-inside: avoid;
  animation: ${slideTop} ${(props) =>
    props.delay * 0.5}s both;
`;

export const ProductImage = styled(Image)`
  position: relative;
  width: inherit;
  min-width: 235.2px;
`;

export const ProductImageOverlay = styled.div<ProductImageOverlayProps>`
  display: ${props => props.show ? 'flex' : 'none'};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: inherit;
  background: #1E2D46BF;
  height: 99%;
  align-items: flex-end;
  padding: 0.5rem;
  animation: ${props => (props.show ? fadeIn : fadeOut)} 0.3s ease-out forwards; // Use animation for initial fade-in

  .masked-row {
    width: 100%;
  }

  button {
    position: absolute;
    bottom: 0.625rem;
    right: 0.313rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.125rem !important;
    z-index: 2;
    span {
      svg {
        transform: scale(0.8);
      }
    }
  }
`;

export const MaskPreview = styled.div`
  padding: 1.5rem;
  display: flex;
  align-items: flex-end;
  gap: 3.438rem;
`;

export const MaskInfo = styled.div`
  color: white;
  font-family: Raleway;
  padding-bottom: 0.2rem;
  padding: 1.2rem;

  h3 {
    font-weight: 600;
    font-size: 1.125rem;
    margin-bottom: 0.438rem;
  }

  p {
    font-family: Raleway;
    font-weight: 200;
    font-size: 1.125rem;
    margin: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
    overflow: hidden;
  }
`;

export const MaskIconContainer = styled.div`
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.938rem;
  border-radius: 50%;
  background: white;
`;

export const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  gap: 20px;
  min-height: 100dvh;
`;

export const DrawerHeader = styled.div`
  padding: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #C1A050;
  cursor: pointer;

  p {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 400;
    margin: 0;
    font-family: Raleway;
  }

  &:hover {
    text-decoration: underline;
  }
`;
export const DrawerWrapper = styled.div`
  padding: 40px;
`;

export const DrawerBody = styled(Row)`
  cursor: pointer;
  margin-top: 1.5rem;
`;

export const DrawerFooter = styled.div`
  text-align: center;
  padding: 0 40px;
  padding-bottom: 30%;
  button {
    width: 100%;
  }
  p {
    font-weight: 400;
    font-size: 18px;
    font-family: Raleway;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const CollectionDrawer = styled.div<CollectionDrawerProps>`
  width: 100%;
  max-width: 442px;
  // height: ${props => props.height ?? 100}px;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: -2px 0px 5px 0 rgba(0, 0, 0, 0.5);
`;

export const ProductWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const ProductDetails = styled.div`
  position: relative;
  font-family: Raleway;
  font-size: 18px;
  p:nth-child(1) {
    margin: 0;
    font-weight: 600;
  }

  p:nth-child(2) {
    font-weight: 400;
  }

  div {
    position: absolute;
    top: -10px;
    right: -35px;
  }
`;
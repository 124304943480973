import { Button, Carousel, Col, Image, Row } from 'antd';
import styled from 'styled-components';
import { theme } from '../../../styles/theme';
import { SubImageWrapperProps } from './type';

export const MainContainer = styled.div`
  padding: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.875rem;

  @media (min-width: 767px) {
    margin-top: 3rem;
  }
`;

export const BackButton = styled(Button)`
  display: flex;
  align-items: center;
  span {
    font-family: Raleway;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.313rem;
    letter-spacing: 0em;
    text-align: left;
  }

  &:hover {
    color: ${theme.color.secondary} !important;
    background: transparent !important;
    cursor: pointer;
    span {
      svg {
        path {
          fill: ${theme.color.secondary};
        }
      }
    }
  }
`;

export const ProjectImageContainer = styled(Row)`
  width: 100%;
`;

export const ProductImagesContainer = styled.div`
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 0.938rem;

  .ant-image {
    width: calc(42dvw - 49%);
  }
`;

export const ProjectTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.25rem;

  div {
    color: black;
    font-family: Raleway;
    h1 {
      font-size: 2rem;
      font-weight: 400;
      margin: 0;
      margin-bottom: 0.563rem;
    }
    h3 {
      font-size: 1.125;
      font-weight: 500;
      margin: 0;
    }
  }
`;

export const ProjectDescription = styled.p`
  font-family: Raleway;
  font-size: 1.125rem;
  font-weight: 200;
  line-height: 1.313rem;
  letter-spacing: 0em;
  text-align: left;
`;

export const ProjectContentContainer = styled.div`
  padding: 1.188rem;
`;

export const ProjectContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.625rem;

  div {
    font-family: Raleway;
    font-size: 1.125rem;
    label {
      font-weight: 600;
      line-height: 1.313rem;
      margin: 0;
    }

    p {
      margin: 0;
      margin-top: 0.438rem;
      font-weight: 200;
      line-height: 1.688rem;
    }
  }
`;

export const SubImageWrapper = styled(Col) <SubImageWrapperProps>`
  position: relative;
  width: inherit;
  max-height: ${props => props.height / 2.1}px;
`;

export const SubImageWrapperProj = styled.div`
  position: relative;
  width: 100%;

  .ant-image {
    width: inherit;
    height: inherit;
    object-fit: contain;
  }
`;

export const SubImageProj = styled(Image)`
  width: 100%;
  object-fit: contain;
`;

export const SubImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const SubImageBtnWrapper = styled.div`
  position: absolute;
  bottom: 1rem;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
`;

export const ProjectDetailsContainer = styled(Row)`
  width: 100%;
  .ant-col:nth-child(2) {
    font-family: Raleway;
    font-size: 1.125rem;
    line-height: 1.313rem;
    letter-spacing: 0em;
    text-align: left;

    strong {
      font-weight: 500;
    }
    p {
      font-weight: 200;
    }
  }
`;

export const StyledCarousel = styled(Carousel)`
  margin-top: 1rem;
  display: none;
  .slick-track {
    display: flex;
  }

  > .slick-dots li button {
    background: ${theme.bg.primary};
  }

  > .slick-dots li.slick-active button {
    background: ${theme.bg.primary};
  }

  > .slick-prev {
    z-index: 2;
    left: 20px;
  }

  > .slick-next {
    z-index: 2;
    right: 20px;
  }

  > .slick-arrow {
    display: flex !important;
    justify-content: center;
    align-items: center;
    font-size: inherit;
    color: ${theme.bg.primary};
    background: #ffffff80;
    border-radius: 100%;

    &:hover {
      color: ${theme.bg.primary};
      background: white;
    }
  }

  .slick-list {
    margin: 0 -10px;
    .slick-track .slick-slide {
      margin: 0 10px;
    }
  }

  @media (min-width: 1024px) {
    display: flex;
  }
`;
import styled from 'styled-components';

export const ImageContainer = styled.div`
  position: relative;
  width: 100%;

  &:hover .tooltip {
    display: block;
  }
`;

export const CircleGroup = styled.div``;

export const CircleOverlay = styled.div`
  position: absolute;
  top: 50%; /* Adjust the top position based on your coordinates */
  left: 50%; /* Adjust the left position based on your coordinates */
  width: 36px; /* Adjust as needed */
  height: 36px; /* Adjust as needed */
  border-radius: 50%;
  background-color: #ffffff75;
  border: 12px solid #1e2d4680; /* Adjust color and thickness as needed */
  box-shadow: 0px 0px 4px 2px #1e2d4680;
  transition: background-color 0.3s;

  &:hover {
    background-color: #000000a0;
  }
`;

export const StyledTooltip = styled.div`
  position: absolute;
  background-color: #1e2d4680;
  color: #ffffff;
  padding: 8px;
  border-radius: 4px;
  font-size: 1.5vw;
  display: none;
  pointer-events: none;
  z-index: 1;
  text-align: center;
`;

export const ProjectImage = styled.img`
  object-fit: contain;
  width: 100%;
`;
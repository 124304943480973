import { Button, Carousel, Image, Row } from "antd";
import styled from "styled-components";
import { theme } from "../../../styles/theme";

export const MainContainer = styled.div`
  padding: 2.5rem;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.875rem;

  .main-row {
    width: 100%:
  }

  @media (min-width: 767px) {
    margin-top: 3rem;
  }
`;

export const CenterWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const BackButton = styled(Button)`
  display: flex;
  align-items: center;
  span {
    font-family: Raleway;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.313rem;
    letter-spacing: 0em;
    text-align: left;
  }

  &:hover {
    color: ${theme.color.secondary} !important;
    background: transparent !important;
    cursor: pointer;
    span {
      svg {
        path {
          fill: ${theme.color.secondary};
        }
      }
    }
  }
`;

export const ProductImage = styled.img`
  width: 100%;
`;

export const ProductDetailHeadingRow = styled(Row)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .heading-space {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`;

export const ProductTitleContainer = styled.div`
  justify-content: space-between;
  align-items: center;
  gap: 1.25rem;

  div {
    color: black;
    font-family: Raleway;
    h1 {
      font-size: 2rem;
      font-weight: 400;
      margin: 0;
    }
    h3 {
      font-size: 1.125rem;
      font-weight: 500;
      margin: 0;
      padding-top: 10px;
    }

  }

  @media (min-width: 767px) {
    margin-top: 1.5rem;
  }
`;

export const ProductDescription = styled.p`
  font-family: Raleway;
  font-size: 1.125rem;
  font-weight: 200;
  line-height: 1.313rem;
  letter-spacing: 0em;
  text-align: left;
`;

export const ProductContentContainer = styled.div`
  padding: 1.188rem;

  .ant-row {
    width: 100%;
  }
`;

export const ProductContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.625rem;

  div {
    font-family: Raleway;
    font-size: 1.125rem;
    label {
      font-weight: 600;
      line-height: 1.313rem;
      margin: 0;
    }

    p {
      margin: 0;
      margin-top: 0.438rem;
      font-weight: 200;
      line-height: 1.688rem;
    }
  }
`;

export const ProductDetailsItem = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 20px;
  div {
    h2 {
      margin: 0;
      margin-bottom: 3px;
      font-weight: 600;
      font-size: 18px;
    }
    p {
      margin: 0;
      margin-bottom: 3px;
      font-weight: 200;
      font-size: 18px;
    }
  }
`;

export const ProductDetailsIconImg = styled.img`
  width: 68px;
  height: 68px;
  object-fit: contain;
`;

export const SeeMoreDetailsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  a {
    text-decoration: underline;
    color: black;
    font-size: 18px;
    font-weight: 600;
  }
`;

export const StyledCarousel = styled(Carousel)`
  margin-top: 1rem;
  display: none;
  .slick-track {
    display: flex;
  }

  > .slick-dots li button {
    background: ${theme.bg.primary};
  }

  > .slick-dots li.slick-active button {
    background: ${theme.bg.primary};
  }

  > .slick-prev {
    z-index: 2;
    left: 20px;
  }

  > .slick-next {
    z-index: 2;
    right: 20px;
  }

  > .slick-arrow {
    display: flex !important;
    justify-content: center;
    align-items: center;
    font-size: inherit;
    color: ${theme.bg.primary};
    background: #ffffff80;
    border-radius: 100%;

    &:hover {
      color: ${theme.bg.primary};
      background: white;
    }
  }

  .slick-list {
    margin: 0 -10px;
    .slick-track .slick-slide {
      margin: 0 10px;
    }
  }

  @media (min-width: 1024px) {
    display: flex;
  }
`;

export const SubImageWrapper = styled.div`
  position: relative;
  width: 100%;
  // height: 300px;

  .ant-image {
    width: inherit;
    height: inherit;
    object-fit: contain;
  }
`;

export const SubImage = styled(Image)`
  width: 100%;
  // height: 200px !important;
  object-fit: contain;
`;
import styled, { keyframes } from 'styled-components';
import { theme } from '../../styles/theme';
import { ProductImageOverlayProps, OpenPreviewContainerProps, AvatarContainerProps, ProductImageContainerProps, ProductsCategoryContainerProps } from './type';
import { Card, Carousel, Checkbox, Image, Row } from 'antd';


const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const slideTop = keyframes`
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const MainContainer = styled.div`
  max-width: 1200px;
  align-self: center;

  @media (max-width: 770px) {
    width: 100%;
    padding: 0;
  }
`;

export const ProductsWrapper = styled.div`
  position: relative;
  display: block;
  padding: 2rem;
  padding-top: 0;

  > .shop-wrapper {
    display: flex;
    gap: 1.5rem;
    position: relative;
  }

  @media (max-width: 770px) {
    padding: .5rem;
    > .shop-wrapper {
      display: block;
    }
  }
`;

export const ShopContent = styled.div`
  flex: 6;

  @media (max-width: 770px) {
    flex: 1;
  }
`;

export const ProductsCategoryContainer = styled.div<ProductsCategoryContainerProps>`
  width: 100%;
  text-align: center;
  padding: 2rem;
  position: ${(props) => props.sticky ? 'sticky' : 'relative'};
  top: 0;
  z-index: 1;
  background: white;

  @media (max-width: 770px) {
    padding: .5rem;
  }
`;

export const ProductsTitle = styled.h1`
  font-family: Raleway;
  font-weight: 400;
  font-size: 2rem;
  color: ${theme.color.primary};
  text-transform: uppercase;
`;

export const ProductCategories = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2.5rem;

  > p {
    font-family: Raleway;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 770px) {
    flex-wrap: wrap;
    margin-top: 4rem;
  }
`;

export const ProductSubcategories = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  height: fit-content;
  width: 100%;
  position: sticky;
  flex: 1;
  top: 245px;
  // > p {
  //   font-family: Raleway;
  //   font-size: 16px;
  //   font-weight: 400;
  //   text-transform: uppercase;
  //   cursor: pointer;
  //   &:hover {
  //     text-decoration: underline;
  //   }
  // }
`;

export const AvatarContainer = styled.div<AvatarContainerProps>`
  position: relative;
  cursor: pointer;
  p {
    text-align: center;
    text-transform: uppercase;
    font-family: Raleway;
    font-weight: 400;
    font-size: 1rem;
    color: ${theme.color.primary};
    width: inherit;
  }

  div {
    width: 100%;
    position: relative;
    span::before {
      display: ${props => props.selected ? 'block' : 'none'};
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: white;
      opacity: 0.5;
    }
  }

  @media (max-width: 770px) {
    display: block;
  }
`;

export const OpenPreviewContainer = styled.div<OpenPreviewContainerProps>`
  position: absolute;
  bottom: 1.25rem;
  right: 0.625rem;

  button {
    display: ${props => props.show ? 'none' : 'flex'};
    justify-content: center;
    align-items: center;
    padding: 1.125rem !important;
  }
`;

export const MasonryLayout = styled.div`
  margin: 1.25rem auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0.625rem;
  // margin: 1.25rem auto;
  // columns: 3;
  // column-gap: 0.625rem;
  @media (max-width: 770px) {
    columns: 1;
  }
`;

export const ProductImageContainer = styled.div<ProductImageContainerProps>`
  cursor: pointer;
  position: relative;
  width: 100%;
  margin-bottom: 0.7rem;
  break-inside: avoid;
  animation: ${slideTop} ${(props) =>
    props.delay * 0.5}s both;
`;

export const ProductImage = styled(Image)`
  position: relative;
  width: inherit;
  min-width: 235.2px;
`;

export const ProductImageOverlay = styled.div<ProductImageOverlayProps>`
  display: ${props => props.show ? 'flex' : 'none'};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: inherit;
  background: #1E2D46BF;
  height: 99%;
  align-items: flex-end;
  padding: 0.5rem;
  animation: ${props => (props.show ? fadeIn : fadeOut)} 0.3s ease-out forwards; // Use animation for initial fade-in

  .masked-row {
    width: 100%;
  }

  button {
    position: absolute;
    bottom: 0.625rem;
    right: 0.313rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.125rem !important;
    z-index: 2;
    span {
      svg {
        transform: scale(0.8);
      }
    }
  }
`;

export const MaskPreview = styled.div`
  padding: 1.5rem;
  display: flex;
  align-items: flex-end;
  gap: 3.438rem;
`;

export const MaskInfo = styled.div`
  color: white;
  font-family: Raleway;
  padding-bottom: 0.2rem;
  padding: 1.2rem;

  h3 {
    font-weight: 600;
    font-size: 1.125rem;
    margin-bottom: 0.438rem;
  }

  p {
    font-family: Raleway;
    font-weight: 200;
    font-size: 1.125rem;
    margin: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
    overflow: hidden;
  }
`;

export const MaskIconContainer = styled.div`
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.938rem;
  border-radius: 50%;
  background: white;
`;

export const ProjectsWrapper = styled.div`
  padding: 5rem;
  max-width: 100%;
`;

export const ProjectsHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Raleway;

  h1 {
    font-size: 2rem;
    font-weight: 400;
  }

  p {
    font-weight: 300;
    font-size: 1.125rem;
    text-align: center;
  }
`;

export const ProjectListContainer = styled(Row)`
  margin: 5rem 0;
`;

export const ProjectDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.625rem;
  font-family: Raleway;
  line-height: 1.313rem;
  letter-spacing: 0em;
  text-align: left;

  h2 {
    font-size: 2rem;
    font-weight: 400;
    line-height: 2.375rem;
    margin: 0;
  }

  p:nth-child(1) {
    font-family: Raleway;
    font-size: 1.125rem;
    font-weight: 500;
    margin: 0;
  }

  p {
    font-size: 1.125rem;
    font-weight: 200;
    margin: 0;
  }

  button {
    margin-top: 1.25rem;
  }
`;

export const ProjectContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.625rem;

  div {
    font-family: Raleway;
    font-size: 1.125rem;
    label {
      font-weight: 600;
      line-height: 1.313rem;
      margin: 0;
    }

    p {
      margin: 0;
      margin-top: 0.438rem;
      font-weight: 200;
      line-height: 1.688rem;
    }
  }
`;

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
`;

export const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-inner, .ant-checkbox-input {
    transform: scale(1);
  }
`;

export const BannerImageContainer = styled.a`
  width: 100%;
  max-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
  cursor: pointer;
  img {
    width: 100%;
    max-height: 400px;
    display: block;
    z-index: 1;
    object-fit: cover;
  }

  div {
    position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    z-index: 2;
    p {
      font-size: 2.5rem;
      color: white;
      font-family: Raleway;
    }
  }

  @media (max-width: 770px) {
    max-height: auto;
    height: 100%;
    img {
      max-height: inherit;
      height: inherit;
      object-fit: contain;
    }

    div {
      p {
        font-size: 1.5rem;
        color: white;
        font-family: Raleway;
      }
    }
  }
`;

export const CategoryHighlights = styled.div`
  // margin: 2rem 3.5rem;
  > h3 {
    text-transform: uppercase;
    font-size: 2rem;
    text-align: center;
  }

  > div {
    display: flex;
    gap: 1.2rem;
    width: 100%;
  }

  @media (max-width: 770px) {
    > h3 {
      font-size: 1.2rem;
    }

    > div {
     padding: .5rem;
     flex-direction: column;
    }
  }
`;

export const CategoryHighlightCard = styled(Card)`
  background: #f7f6f4;
  // margin: 1.5rem 3rem;
  border: 0;
  border-radius: .5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1.2rem;
  cursor: pointer;

  .ant-card-body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;

    p {
      font-weight: 500;
      font-size: 1.5rem;
      text-align: center;
    }

    img {
      max-width: 200px;
      mix-blend-mode: multiply;
    }
  }
`;

export const NoDataWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;

  p {
    color: gray;
    font-size: 1rem;
  }
`;

export const StyledCarousel = styled(Carousel)`
  margin-top: 1rem;
  display: none;

  .slick-track {
    display: flex;
  }

  > .slick-dots li button {
    background: ${theme.bg.primary};
  }

  > .slick-dots li.slick-active button {
    background: ${theme.bg.primary};
  }

  > .slick-prev {
    z-index: 2;
    left: 20px;
  }

  > .slick-next {
    z-index: 2;
    right: 20px;
  }

  > .slick-arrow {
    display: flex !important;
    justify-content: center;
    align-items: center;
    font-size: inherit;
    color: ${theme.bg.primary};
    background: #ffffff80;
    border-radius: 100%;

    &:hover {
      color: ${theme.bg.primary};
      background: white;
    }
  }

  .slick-list {
    margin: 0 -10px;
    .slick-track .slick-slide {
      margin: 0 10px;
    }
  }

  @media (min-width: 1024px) {
    display: block;
  }
`;

export const SubImageWrapperProj = styled.div`
  position: relative;
  width: 100%;

  .ant-image {
    width: inherit;
    height: inherit;
    object-fit: contain;
  }
`;

export const SubImageProj = styled(Image)`
  width: 100%;
  object-fit: contain;
`;
import styled from 'styled-components';
import { theme } from '../../styles/theme';
import { Divider, Row } from 'antd';

export const FooterSection = styled.section`
  background-color: ${theme.bg.primary};
  padding: 5rem 12rem;

  @media (max-width: 770px) {
    width: max-content;
    padding: 1.5rem;
  }
`;

export const FooterFlexWrapper = styled.div`
  display: flex;
  gap: 1.5rem;

  @media (max-width: 770px) {
    flex-direction: column;

    table {
      display: none;
    }

    .img-footer {
      display: none;
    }
  }
`;

export const MobileFlexWrapper = styled.div`
  color: white;

  a {
    color: white;
  }

  h2 {
    font-weight: 300;
    font-size: 17px;
    margin-bottom: 15px;
    font-family: Raleway;
  }

  > .contact-us {
    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        padding-left: 1rem;
        font-family: Raleway;
        font-size: 16px;
        font-weight: 200;
        margin-bottom: 8px;
        svg {
          margin-right: 10px;
        }

        a {
          color: white;
        }
      }
    }
  }

  > .follow-us {
    .socials {
      display: flex;
      gap: 15px;
      padding: 0 0 1rem .75rem;
    }
  }

  > .opening-hours {
    > .schedule-appointments {
      .schedule-item {
        > .day {
          font-family: Raleway;
          text-decoration: none;
          color: inherit;
          font-size: 16px;
          font-weight: 300;
        }
      }
    }
  }

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const FooterWidget = styled.tr`
  color: white;
  h2 {
    font-weight: 300;
    font-size: 17px;
    margin-bottom: 15px;
    font-family: Raleway;
  }
  > div {
    padding: 0 1rem;
    font-weight: 200;
    font-size: 16px;
    font-family: Raleway;

    .time-section {
      font-weight: bolder;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;


    li {
      padding-left: 1rem;
      font-family: Raleway;
      font-size: 16px;
      font-weight: 200;
      margin-bottom: 8px;
      svg {
        margin-right: 10px;
      }
    }
  }


  .socials {
    display: flex;
    gap: 15px;
    padding: 0 0 2rem 1rem;
  }

  a {
    font-family: Raleway;
    text-decoration: none;
    color: inherit;
    font-size: 1em;
    transition: transform 0.3s;
    transition-delay: 0.1s;

    &:hover {
      transform: scale(0.7);
    }
  }

  > .tbody-td {

    > .follow-us {
      margin-top: 2rem;
    }

    > .open-appointments {
      > p {
        text-transform: uppercase;
        font-family: Raleway;
        text-decoration: none;
        color: inherit;
        font-size: 1.2em;
        font-weight: 300;
        width: 70%;
      }
    }

    > .table-schedule {
      tr {
        font-size: 16px;
        font-family: Raleway;
        text-transform: capitalize;
        > td {
          padding: 0.45rem;
          &:nth-child(1) {
            font-weight: 200;
          }
        }
      }
    }
  }

  > th {
    text-align: left;
  }
`;

export const StyledRow = styled(Row)`
  margin-left: auto;
  margin-right: auto;
`;

export const DividerWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 1.5 rem;
`;

export const StyledDivider = styled(Divider)`
  border-bottom: 1px solid white;
`;
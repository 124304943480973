import React from "react";
import { Modal } from "antd";
import RequestForm from "./RequestForm";
import { useTranslation } from 'react-i18next';

const RequestModal = ({ visible, onCancel, onFinish, loading }: any) => {
  const { t } = useTranslation();
  return (
    <Modal
      title={t('form.title.request_form')}
      open={visible}
      onCancel={onCancel}
      footer={null}
    >
      <RequestForm onFinish={onFinish} loading={loading} />
    </Modal>
  );
};

export default RequestModal;

import { Row } from 'antd';
import styled from 'styled-components';

export const ProjectsWrapper = styled.div`
  padding: 5rem;
  max-width: 100%;
`;

export const ProjectsHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Raleway;

  h1 {
    font-size: 2rem;
    font-weight: 400;
  }

  p {
    font-weight: 300;
    font-size: 1.125rem;
    text-align: center;
  }
`;

export const ProjectListContainer = styled(Row)`
  margin-top: 5rem;
`;

export const ProjectDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  font-family: Raleway;
  line-height: 1.313rem;
  letter-spacing: 0em;
  text-align: left;

  h2 {
    font-size: 2rem;
    font-weight: 400;
    line-height: 2.375rem;
    margin: 0;
  }

  p:nth-child(1) {
    font-family: Raleway;
    font-size: 1.125rem;
    font-weight: 500;
    margin: 0;
  }

  p {
    font-size: 1.125rem;
    font-weight: 200;
    margin: 0;
  }

  button {
    margin-top: 1.25rem;
  }
`;

export const ProjectContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.625rem;

  div {
    font-family: Raleway;
    font-size: 1.125rem;
    label {
      font-weight: 600;
      line-height: 1.313rem;
      margin: 0;
    }

    p {
      margin: 0;
      margin-top: 0.438rem;
      font-weight: 200;
      line-height: 1.688rem;
    }
  }
`;

import { collection, doc, getDoc, getDocs, query } from "firebase/firestore";
import { useCallback, useState } from "react";
import { db } from "../../firebase/config";

export default function useGetAllProjects(): any {
  const [user, setUser] = useState<any>();
  const [ids, setIds] = useState<string[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const refetch = useCallback(() => {
    async function fetchData() {
      setLoading(true);
      const docRef = query(collection(db, "projects"));

      await getDocs(docRef)
        .then((docSnap) => {
          const databaseInfo: any[] = [];
          const dataIds: string[] = [];

          docSnap.forEach((doc) => {
            databaseInfo.push({
              ...doc.data(),
              id: doc.id,
            });
            dataIds.push(doc.id);
          });

          setIds(dataIds);
          setUser(databaseInfo);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    fetchData();
  }, []);

  return {
    data: user,
    ids,
    fetchData: refetch,
    loading,
  };
}

export function useGetProject(): any {
  const [user, setUser] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const refetch = useCallback((id: string) => {
    function fetchData() {
      setLoading(true);
      const d = doc(db, "projects", id);
      getDoc(d).then((docSnap) => {
        if (docSnap.exists()) {
          setUser({
            ...docSnap.data(),
            id: docSnap.id,
          });
          return {
            ...docSnap.data(),
            id: docSnap.id,
          };
        }
        return null;
      });
      setLoading(false);
    }
    fetchData();
  }, []);

  return {
    data: user,
    fetchData: refetch,
    loading,
  };
}

import { useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Badge, Button, Dropdown, MenuProps } from 'antd';
import { CloseOutlined, CaretDownFilled, MenuOutlined } from '@ant-design/icons';
import banqaLogo from '../../assets/images/banqa_logo.png';
import { Container, HamburgerButtonContainer, LinkContainer, Logo, LogoContainer, MobileContainer } from './style'
import Icon from '../../components/Icon/Icon';
import { useCollection, useCollectionActions } from '../../context/collections';
import { useTranslation } from 'react-i18next';
import ReactCountryFlag from 'react-country-flag';
import Sidebar from '../Sidebar';

const Header = () => {
  const { pathname } = useLocation();
  const { state } = useCollection();
  const { t, i18n } = useTranslation();
  const { toggleDrawer } = useCollectionActions();
  const [open, setOpen] = useState<boolean>(false);

  const selectedLanguage = useMemo(() => {
    return i18n.language !== 'gb' ? 'ES' : 'GB';
  }, [i18n.language]);

  const toggleMenu = () => {
    setOpen((prev) => (!prev));
  };

  const onChangeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  const items: MenuProps['items'] = [
    {
      label: <ReactCountryFlag onClick={() => onChangeLanguage('gb')} countryCode="GB" svg />,
      key: 'gb',
    },
    {
      label: <ReactCountryFlag onClick={() => onChangeLanguage('es')} countryCode="ES" svg />,
      key: 'es',
    },
  ];

  return (
    <>
      <HamburgerButtonContainer isHidden={open}>
        <Button
          onClick={toggleMenu}
          type="text"
          icon={<MenuOutlined />}
        />
      </HamburgerButtonContainer>
      <MobileContainer className={open ? 'open' : ''}>
        <div className="logo-wrapper">
          <Button
            type="text"
            onClick={toggleMenu}
            icon={<CloseOutlined size={40} color="white" />}
          />
          <LogoContainer>
            <Logo src={banqaLogo} />
          </LogoContainer>
          {/* <div>&nbsp;</div> */}
        </div>
        <Sidebar
          i18n={i18n}
          items={items}
          onChangeLanguage={onChangeLanguage}
          selectedLanguage={selectedLanguage}
          t={t}
          state={state}
          pathname={pathname}
          toggleDrawer={toggleDrawer}
          toggleMenu={toggleMenu}
          banqaLogo={banqaLogo}
        />
      </MobileContainer>
      <Container>
        <LinkContainer>
          <Dropdown
            menu={{
              items,
              selectable: true,
              defaultSelectedKeys: [i18n.language ?? 'gb'],
              onClick: (e) => onChangeLanguage(e.key)
            }}
            trigger={['click']}
          >
            <div className="translation-wrapper">
              <ReactCountryFlag
                countryCode={selectedLanguage}
                svg
                style={{
                  width: '1.2em',
                  height: '1.2em',
                }}
              />
              <CaretDownFilled style={{ color: 'white' }} />
            </div>
          </Dropdown>
          <Link to="/shop">{t('navbar.shop')}</Link>
          {/* <Link to="/projects">PROJECTS</Link> */}
          <a href="https://www.banqa.es/portfolio">{t('navbar.portfolio')}</a>
          <a href="https://www.banqa.es/brands">{t('navbar.brands')}</a>
          <a href="https://www.banqa.es/homestaging/">{t('navbar.homestaging')}</a>
          <LogoContainer>
            <Logo src={banqaLogo} />
          </LogoContainer>
          <a href="https://www.banqa.es/interior-designer/">{t('navbar.interiorDesigner')}</a>
          <a href="https://www.banqa.es/about/">{t('navbar.about')}</a>
          <a href="https://www.banqa.es/art/">{t('navbar.art')}</a>
          <a href="https://www.banqa.es/contact-us/">{t('navbar.contact')}</a>
          {pathname !== '/collections' && (
            <Badge count={state.items.length}>
              <Button
                onClick={toggleDrawer}
                type='text'
                style={{ padding: 0 }}
                icon={
                  <Icon
                    name='collections'
                    size={20}
                  />
                }
              />
            </Badge>
          )}
        </LinkContainer>
      </Container>
    </>
  )
}

export default Header;
